import React, { useContext, useEffect } from 'react';
import { KfHeroUtility } from '@klickinc/kf-react-components';
import AppContext from "@src/context"
import Loadable from '@loadable/component';
import { handleFBQ } from '@utils/FBQ';
// Utility imports
import Image from '@components/utility/Image';
import { withArtDirection, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import Seo from '@components/utility/SEO';
import './styles.scss';

// Content imports
import BottomCta from '@components/content/BottomCta';
const VideoPlayer = Loadable(() => import("@components/content/VideoPlayer.jsx"));

const GettingStarted = ({ data }) => {
	const ctx = useContext(AppContext);

	const image_verticalLine = withArtDirection(getImage(data.verticalLineMedium), [
		{
			media: "(max-width: 640px)",
			image: getImage(data.verticalLineSmall),
		},
	]);
	const image_phone = getImage(data.phone);
	const image_ptcCares = getImage(data.ptcCares);
	const image_bgIcon1 = getImage(data.bgIcon1);
	const image_bgIcon2 = getImage(data.bgIcon2);
	const image_ctaSparkles = getImage(data.ctaSparkles);

	useEffect(() => {
		ctx.setPageClass('page--getting-started');
	}, []);

	return (<>
		<section className='relative'>
			<div className='bg-1-image'>
				<Image
					imageData={image_verticalLine}
					alt=''
					loading='eager'
				/>
			</div>
			<div className="container container--inner container--padded">
				<KfHeroUtility addedClass='heading heading-hero-blue xl:pt-14'>
					How can <br /> PTC <span className='italic'>Cares</span>™ help?
				</KfHeroUtility>
				<div className='max-w-3xl'>
					<div className='contact flex flex-col md:flex-row items-start justify-between'>
						<div className='ptc-phone'>
							<div className='md:pr-9 md:border-r-[2px]'>
								<p>Once you and your son's healthcare provider have decided to start EMFLAZA, simply fill out a <a href='/prescription-start-form.pdf' target="_blank" rel="noopener noreferrer" className='underline hover:no-underline focus:no-underline' onClick={() => handleFBQ('InitiateCheckout')}>Prescription Start Form</a> together to begin the journey. PTC <span className='italic'>Cares</span>&trade; manages every step of the prescription process from there.</p>
								<div className='flex flex-row my-8'>
									<Image
										imageData={image_phone}
										alt=''
										loading='eager'
									/>
									<h2 className='self-end text-xl md:text-2xl font-bold text-emflaza-blue-200'>Call <a className="kf-anchorlink underline hover:no-underline focus:no-underline" href="tel:18444782227">1-844-478-2227</a> <br /> <span className='xl:whitespace-nowrap text-[0.85rem] sm:text-xl'>Monday &#8211; Friday 8&nbsp;<span className='text-sm'>AM</span>&nbsp;&#8211;&nbsp;6&nbsp;<span className='text-sm'>PM</span>,&nbsp;ET</span></h2>
								</div>
							</div>
							<a href='https://ptccares.com/' target="_blank" rel="noreferrer noopener" className='visit-link link-btn link-btn--blue md:max-w-[18.75rem] md:mx-0 md:mr-auto' onClick={() => handleFBQ('Donate')}>Visit PTCCares.com</a>
						</div>
						<div className='ptc-cares sm:pl-9'>
							<a href='https://ptccares.com/' target="_blank" rel="noreferrer noopener">
								<span className='sr-only'>Visit PTCCares.com</span>
								<span aria-hidden='true'>
									<Image
										imageData={image_ptcCares}
										alt='PTC Cares&trade;'
										loading='eager'
									/>
								</span>
							</a>
						</div>
					</div>
					<div className='process'>
						<h2 className='my-5 text-base font-black uppercase md:text-lg text-emflaza-orange-100'>The prescription process</h2>
						<p>When you enroll in PTC <span className='italic'>Cares</span>, you'll be activating your most valuable resource&mdash;a dedicated support team for your family throughout your journey. PTC will verify your insurance coverage, work with your specialty pharmacy, and review your out&#8209;of&#8209;pocket costs.</p>
						<div className='flex flex-col md:flex-row items-start justify-between pt-16 sm:pb-11'>
							<div className='process--step'>
								<Image
									imageData={image_bgIcon1}
									alt='1'
									loading='eager'
								/>
								<h3 className='orange-heading'>Obtaining a prescription</h3>
								<p>Fill out the <a className='underline hover:no-underline focus:no-underline' href='/prescription-start-form.pdf' target='_blank' rel='noopener noreferrer' onClick={() => handleFBQ('InitiateCheckout')}>Prescription Start Form</a> with your son's healthcare provider. </p>
							</div>
							<div className='process--step'>
								<Image
									imageData={image_bgIcon2}
									alt='2'
									loading='eager'
								/>
								<h3 className='orange-heading'>Understanding your benefits</h3>
								<p>Your dedicated Case Manager will walk you through the details of your insurance benefits and any additional financial assistance programs that may be available. They will also make sure you get your medication from the specialty pharmacy.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section className='process-cta'>
			<div className='resources-cta--inner container--inner container container--padded'>
				<div className='resources-cta--copy relative'>
					<h3 className='orange-heading uppercase'>Connect with a peer navigator</h3>
					<p className='mt-4 mb-10'>Learning how others have managed challenges and achieved successes can help you gain insight into decisions you may need to make for your family. The Peer Navigator Program can connect you with other families who understand your journey. Your family can choose to speak with English- or Spanish-speaking navigators, according to your preferences.</p>
					<div className='flex flex-col md:flex-row'>
						<a href='https://ptccares.com/support-beyond-treatment/#connect-with-peers' target='_blank' rel="noopener noreferrer" className='link-btn link-btn--blue mr-6 mb-8 sm:mb-0' onClick={() => handleFBQ('Donate')}>Contact Us</a>
						<Image
							imageData={image_ctaSparkles}
							alt=''
							loading='eager'
						/>
					</div>
				</div>
			</div>
		</section>
		<section className='find-your-ptc-cares-manager'>
			<div className="container container--inner container--padded">
				<div className='max-w-3xl'>
					<h2 className='heading'>FIND YOUR PTC <span className='italic'>Cares</span>™ Case <br className='sm:hidden' /> Manager</h2>
					<p className='copy'>Connect with your own PTC <span className='italic'>Cares</span>™ Case Manager. They will always be at your side, helping you address potential challenges and serving as a resource throughout your journey. Get started below.</p>
					<div className='search flex flex-col md:flex-row items-center justify-between'>
						<a href='https://ptccares.com/how-we-help/#who-we-are' target='_blank' className='link-btn link-btn--blue' onClick={() => handleFBQ('Donate')} rel='noopener noreferrer'>Search</a>
						<p className='my-6'>or</p>
						<div>
							<h2 className='self-end text-xl md:text-2xl font-bold text-emflaza-blue-200'>Call <a className="kf-anchorlink underline hover:no-underline focus:no-underline" href="tel:18444782227">1-844-478-2227</a> <br /> <span className='xl:whitespace-nowrap text-[1rem] sm:text-xl'>Monday &#8211; Friday 8&nbsp;<span className='text-sm'>AM</span>&nbsp;&#8211;&nbsp;6&nbsp;<span className='text-sm'>PM</span>,&nbsp;ET</span></h2>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section>
			<div id='losapios' className="container container--inner pt-10 md:pt-20 xl:pt-14 relative">
				<h3 className='pt-8 heading heading-hero-blue md:pt-12'>Here & now with <br className='hidden lg:block' />the Losapios</h3>
				<p className='mb-5'>Mike has two sons with DMD. Here's how he makes sure they're making the most of the here & now.</p>
				<div className='video'>
					<VideoPlayer id={740850133} />
					<a href='/video-transcript/here-now-losapios' target='_blank' className='gtm-transcript-cta transcript-cta'>View Video Transcript</a>
				</div>
			</div>
		</section>
		<section className='relative pb-20 xl:pb-16'>
			<div className="container container--inner">
				<BottomCta
					ctaUrl="/caregiver-resources"
					image={data}
				>
					Find <br className='block md:hidden' /> resources
				</BottomCta>
			</div>
		</section>
	</>)
}

export default GettingStarted;

export const pageQuery = graphql`
  query {
    verticalLineSmall: file(relativePath: {eq: "pages/getting-started/vertical-line-1-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	verticalLineMedium: file(relativePath: {eq: "pages/getting-started/vertical-line-1.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	phone: file(relativePath: {eq: "pages/getting-started/5-0-phone.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	ptcCares: file(relativePath: {eq: "pages/getting-started/5-0-ptc-cares.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	bgIcon1: file(relativePath: {eq: "pages/getting-started/bg-icon-1.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	bgIcon2: file(relativePath: {eq: "pages/getting-started/bg-icon-2.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	ctaSparkles: file(relativePath: {eq: "pages/getting-started/cta-sparkles.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	leftImage: file(relativePath: {eq: "pages/getting-started/bottom-cta/getting-started-cta-image-1.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	leftImageMobile: file(relativePath: {eq: "pages/getting-started/bottom-cta/getting-started-cta-image-1-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	iconImage: file(relativePath: {eq: "pages/getting-started/bottom-cta/getting-started-cta-image-2.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	rightImage: file(relativePath: {eq: "pages/getting-started/bottom-cta/getting-started-cta-image-3.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	rightImageMobile: file(relativePath: {eq: "pages/getting-started/bottom-cta/getting-started-cta-image-3-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
}
`
export function Head() {
	return (
		<Seo
			title='How PTC Cares™ Can Help - EMFLAZA® (deflazacort)'
			description='Find information on PTC Cares™ and the prescription process. Connect with a Peer Navigator or find your own PTC Cares Case Manager. See full Prescribing Information & Important Safety Information.'
		>
			<script type="application/ld+json">
				{JSON.stringify({ "@context": "http://schema.org", "type": "VideoObject", "name": "Here & Now With the Losapios: A DMD Story", "description": "Mike has two sons with DMD. Here's how he makes sure they're making the most of the here & now.", "thumbnailUrl": "https://i.vimeocdn.com/video/1495639936-9394d172c865f6d6bcc0d7ea13c0525e6c9c92c3f79050852a191a56a3c0c75e-d", "uploadDate": "2022-09-30", "embedUrl": "https://www.emflaza.com/ptc-cares-helps", "contentUrl": "https://player.vimeo.com/video/740850133", "duration": "PT5M45S" })}
			</script>
		</Seo>
	);
};
